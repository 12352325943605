import {
    EntityFactory,
    EntityRef,
    IPageDraft,
    Page,
    PageDraft,
    Container,
    CustomListContent,
    RichTextContent,
    AccordionContent,
    AccordionGroupContent,
    DocumentContent,
    EntityType,
    ContainerFactory,
    DecisionTreeContent,
    ContainerContentType,
    WhosMyLawyerContent
} from "@amzn/ask-legal-domain";
import { Builder } from "builder-pattern";
import * as React from "react";
import { useAPI } from "../hooks/api-hook";
import { AppContext } from "../setup/context";
import { UIModel } from "./ui-model";
import { ContainerModel } from "./container-model";
import { PageModel } from "./page-model";

export namespace PageDraftModel {
    export const DOC_DESCRIPTION_CHAR_LIMIT = 200;
    export class EditContainerState {
        contentField: UIModel.State<ContainerContentType>;
        titleField: UIModel.State<string>;
        descriptionField: UIModel.State<string>;
        headerDescriptionHeaderField: UIModel.State<string>;
        headerDescriptionBodyField: UIModel.State<string>;
        footerDescriptionHeaderField: UIModel.State<string>;
        footerDescriptionBodyField: UIModel.State<string>;

        activeContainerField: UIModel.State<Container.Data | null>;
        activeContainerTypeField: UIModel.State<Container.Type | null>;
        locationField: UIModel.State<Page.SectionLocation>;
        draftRef: EntityRef;
        validState: boolean;
        init: (params: {
            container?: Container.Data;
            containerContent?: ContainerContentType;
        }) => void;
        reset: () => void;

        static isContentValid = (
            containerType: Container.Type,
            content?: ContainerContentType
        ): boolean => {
            if (!content) return;
            let validContent = false;
            switch (containerType) {
                case Container.Type.RICH_TEXT:
                    validContent = (content as RichTextContent).contentValue.length > 0;
                    break;
                case Container.Type.ACCORDION:
                    validContent = (content as AccordionContent).contentValue.questions.length > 0;
                    break;
                case Container.Type.ACCORDION_GROUP:
                    validContent = (content as AccordionGroupContent).contentValue.questionGroups.length > 0;
                    break;
                case Container.Type.LIST:
                    const lists = content as CustomListContent;
                    validContent = (
                        lists.contentValue.length > 0 &&
                        lists.contentValue.every(link =>
                            link.key.trim().length > 0 &&
                            (
                                UIModel.FieldValidation.isValidURL(link.value) ||
                                UIModel.FieldValidation.isValidMailto(link.value)
                            )
                        )
                    );
                    break;
                case Container.Type.DOCUMENT:
                    const docs = content as DocumentContent;
                    if (
                        docs.fileGroups.length === 0 || docs.fileGroups.every(group =>
                            !UIModel.FieldValidation.assertNotNullString(group.groupTitle.trim()) &&
                            !UIModel.FieldValidation.assertWithinCharLimit(
                                DOC_DESCRIPTION_CHAR_LIMIT, group.groupDescription, true
                            )
                        )
                    ) validContent = true;
                    break;
                case Container.Type.DECISION_TREE:
                    validContent = (content as DecisionTreeContent).welcomeText?.contentValue.trim().length > 0;
                    break;
                case Container.Type.WHOS_MY_LAWYER:
                    validContent = true;
                default:
                    break;
            }
            return validContent;
        };

        static toAddContainerInput(
            state: EditContainerState
        ): IPageDraft.AddContainerInput {
            return IPageDraft.AddContainerInput.create({
                containerContent: state.contentField.value,
                contentType: state.activeContainerTypeField.value,
                containerTitle: state.titleField.value,
                containerDescription: state.descriptionField.value,
                draftRef: state.draftRef,
                location: state.locationField.value,
                headerDescription: {
                    header: state.headerDescriptionHeaderField.value,
                    body: state.headerDescriptionBodyField.value,
                },
                footerDescription: {
                    header: state.footerDescriptionHeaderField.value,
                    body: state.footerDescriptionBodyField.value,
                },
            });
        }

        static toEditContainerInput(
            state: EditContainerState
        ): IPageDraft.EditContainerInput {
            return IPageDraft.EditContainerInput.create({
                newContainerContent: state.contentField.value,
                newTitle: state.titleField.value,
                newDescription: state.descriptionField.value,
                newHeaderDescription: {
                    header: state.headerDescriptionHeaderField.value,
                    body: state.headerDescriptionBodyField.value,
                },
                newFooterDescription: {
                    header: state.footerDescriptionHeaderField.value,
                    body: state.footerDescriptionBodyField.value,
                },
                draftRef: state.draftRef,
                containerRef: ContainerFactory.toContainerRef(state.activeContainerField.value)
            });
        }

        static use(params: {
            draftRef: EntityRef;
        }): EditContainerState {
            const contentField = UIModel.State.use({ initialValue: null });
            const titleField = UIModel.State.useNotNullStringWithCharLimit({
                initialValue: "",
                characterLimit: ContainerModel.TITLE_CHAR_LIMIT
            });
            const descriptionField = UIModel.State.useNotNullStringWithCharLimit({
                initialValue: "",
                characterLimit: ContainerModel.DESCRIPTION_CHAR_LIMIT
            });
            const headerDescriptionHeaderField = UIModel.State.use<string>({ initialValue: "" });
            const headerDescriptionBodyField = UIModel.State.use<string>({ initialValue: "" });
            const footerDescriptionHeaderField = UIModel.State.use<string>({ initialValue: "" });
            const footerDescriptionBodyField = UIModel.State.use<string>({ initialValue: "" });
            const activeContainerField = UIModel.State.use<Container.Data>({ initialValue: null });
            const activeContainerTypeField = UIModel.State.use<Container.Type>({ initialValue: null });
            const pageSectionLocationField = UIModel.State.use<Page.SectionLocation>({ initialValue: "Main" });

            const [validState, setValidState] = React.useState(false);
            const [baseTitle, setBaseTitle] = React.useState<string>("");
            const [baseContent, setBaseContent] = React.useState<any>(null);
            const [baseDescription, setBaseDescription] = React.useState<string>("");
            const [baseHeaderDescription, setBaseHeaderDescription] = React.useState<Container.Margin>({
                header: "",
                body: ""
            });
            const [baseFooterDescription, setBaseFooterDescription] = React.useState<Container.Margin>({
                header: "",
                body: ""
            });

            const initEmptyContent = (containerType: Container.Type) => {
                let content: any;
                switch (containerType) {
                    case Container.Type.RICH_TEXT :
                        content = RichTextContent.create();
                        break;
                    case Container.Type.LIST :
                        content = CustomListContent.create([]);
                        break;
                    case Container.Type.ACCORDION :
                        content = AccordionContent.create([]);
                        break;
                    case Container.Type.ACCORDION_GROUP :
                        content = AccordionGroupContent.create([]);
                        break;
                    case Container.Type.ACCORDION :
                        content = AccordionContent.create();
                        break;
                    case Container.Type.DOCUMENT :
                        content = DocumentContent.create();
                        break;
                    case Container.Type.DECISION_TREE :
                        content = Builder<DecisionTreeContent>().build();
                        break;
                    case Container.Type.WHOS_MY_LAWYER :
                        content = WhosMyLawyerContent.create({});
                        break;
                    default:
                        content = null;
                }
                setBaseContent(content);
                contentField.setValue(content);
            };

            const reset = () => {
                contentField.setValue(baseContent);
                titleField.setValue(baseTitle);
                descriptionField.setValue(baseDescription);
                headerDescriptionHeaderField.setValue(baseHeaderDescription.header);
                headerDescriptionBodyField.setValue(baseHeaderDescription.body);
                footerDescriptionHeaderField.setValue(baseFooterDescription.header);
                footerDescriptionBodyField.setValue(baseFooterDescription.body);
                setValidState(true);
            };

            const init = (params?: {
                container: Container.Data;
                containerContent: ContainerContentType;
            }) => {
                if (!params) {
                    activeContainerTypeField.setValue(null);
                    activeContainerField.setValue(null);
                    setBaseTitle("");
                    setBaseDescription("");
                    setBaseHeaderDescription({
                        header: "",
                        body: ""
                    });
                    setBaseFooterDescription({
                        header: "",
                        body: ""
                    });
                    initEmptyContent(activeContainerField.value?.containerType);
                    titleField.setValue("");
                    descriptionField.setValue("");
                } else {
                    activeContainerTypeField.setValue(params.container.containerType);
                    setBaseTitle(params.container.title);
                    setBaseDescription(params.container.description);
                    setBaseContent(params.containerContent);
                    contentField.setValue(params.containerContent);
                    titleField.setValue(params.container.title);
                    descriptionField.setValue(params.container.description);
                    activeContainerField.setValue(params.container);

                    if (!!params.container.headerDescription) {
                        setBaseHeaderDescription(params.container.headerDescription);
                        headerDescriptionHeaderField.setValue(params.container.headerDescription.header);
                        headerDescriptionBodyField.setValue(params.container.headerDescription.body);
                    } else {
                        setBaseHeaderDescription({
                            header: "",
                            body: ""
                        });
                    }

                    if (!!params.container.footerDescription) {
                        setBaseFooterDescription(params.container.footerDescription);
                        footerDescriptionHeaderField.setValue(params.container.footerDescription.header);
                        footerDescriptionBodyField.setValue(params.container.footerDescription.body);
                    } else {
                        setBaseFooterDescription({
                            header: "",
                            body: ""
                        });
                    }
                }
            };

            React.useEffect(() => {
                if (!!activeContainerField.value) return;
                // Below is only for adding new container
                reset();
                initEmptyContent(activeContainerTypeField.value);
            }, [activeContainerTypeField.value]);

            React.useEffect(() => {
                if (!activeContainerTypeField.value) return;
                const isValid = (
                    titleField.value?.length > 0 &&
                    descriptionField.value?.length > 0 &&
                    titleField.value.length <= ContainerModel.TITLE_CHAR_LIMIT &&
                    descriptionField.value.length <= ContainerModel.DESCRIPTION_CHAR_LIMIT &&
                    EditContainerState.isContentValid(activeContainerTypeField.value, contentField.value)
                );
                setValidState(isValid);
            }, [titleField.value, contentField.value, descriptionField.value]);

            return Builder<EditContainerState>()
                .activeContainerField(activeContainerField)
                .activeContainerTypeField(activeContainerTypeField)
                .locationField(pageSectionLocationField)
                .draftRef(params.draftRef)
                .contentField(contentField)
                .titleField(titleField)
                .descriptionField(descriptionField)
                .headerDescriptionHeaderField(headerDescriptionHeaderField)
                .headerDescriptionBodyField(headerDescriptionBodyField)
                .footerDescriptionHeaderField(footerDescriptionHeaderField)
                .footerDescriptionBodyField(footerDescriptionBodyField)
                .validState(validState)
                .reset(reset)
                .init(init)
                .build();
        }
    }

    export class UpdateDraftMetaState {
        draft: PageDraft.Data;
        newPageTitleField: UIModel.State<string>;
        newPageDescription: UIModel.State<string>;
        setDraft: (draft: PageDraft.Data) => void;
        reset: () => void;

        static toInput(
            state: UpdateDraftMetaState
        ): IPageDraft.UpdatePageDraftMetaInput {
            return IPageDraft.UpdatePageDraftMetaInput.create({
                draftRef: EntityFactory.toEntityRef(state.draft),
                newPageTitle: state.newPageTitleField.value,
                newPageDescription: state.newPageDescription.value
            });
        }

        static use(props: {
            draft: PageDraft.Data
        }): UpdateDraftMetaState {
            const [draft, _setDraft] = React.useState(props.draft);
            const newPageTitleField = UIModel.State.useNotNullStringWithCharLimit({
                initialValue: props.draft.newPageTitle,
                characterLimit: PageModel.TITLE_CHAR_LIMIT
            });
            const newPageDescriptionField = UIModel.State.useNotNullStringWithCharLimit({
                initialValue: props.draft.newPageDescription,
                characterLimit: PageModel.DESCRIPTION_CHAR_LIMIT
            });

            const reset = () => {
                newPageTitleField.setValue(draft.newPageTitle);
                newPageDescriptionField.setValue(draft.newPageDescription);
            };

            const setDraft = (draft: PageDraft.Data) => {
                _setDraft(draft);
            };

            return Builder<UpdateDraftMetaState>()
                .draft(draft)
                .newPageTitleField(newPageTitleField)
                .newPageDescription(newPageDescriptionField)
                .setDraft(setDraft)
                .reset(reset)
                .build();
        }

    }

    export class ReorderContainerState {
        draftField: UIModel.State<PageDraft.Data>;
        newOrderedSectionsField: UIModel.State<Page.Section[]>;
        isDirty: () => boolean;
        moveUp: (containerRef: EntityRef) => void;
        moveDown: (containerRef: EntityRef) => void;
        reset: () => void;
        setDraft: (draft: PageDraft.Data) => void;

        static toInput(
            state: ReorderContainerState
        ): IPageDraft.UpdatePageDraftSectionInput {
            return IPageDraft.UpdatePageDraftSectionInput.create({
                draftRef: EntityFactory.toEntityRef(state.draftField.value),
                newSections: state.newOrderedSectionsField.value
            });
        }

        static use(props: { draft: PageDraft.Data }): ReorderContainerState {
            const draftField = UIModel.State.use<PageDraft.Data>({
                initialValue: props.draft
            });
            const newOrderedSectionsField = UIModel.State.use<Page.Section[]>({
                initialValue: props.draft.newSections
            });
            const [dirty, setDirty] = React.useState<boolean>();

            const reset = () => {
                setDirty(false);
                newOrderedSectionsField.setValue(draftField.value.newSections);
            };

            const setDraft = (draft: PageDraft.Data) => {
                draftField.setValue(draft);
                newOrderedSectionsField.setValue(draft.newSections);
                setDirty(false);
            };

            const moveUp = (containerRef: EntityRef) => {
                let targetSection;
                let targetContainer;
                let found = false;
                for (const section of newOrderedSectionsField.value) {
                    if (found) break;
                    for (const container of section.containers) {
                        if (found) break;
                        if (container.id === containerRef.id && container.version === containerRef.version) {
                            found = true;
                            targetSection = section;
                            targetContainer = container;
                        }
                    }
                }
                if (!found) return;
                const newOrderedContainers = targetSection.containers.slice();
                const index = newOrderedContainers.findIndex(c =>
                    c.id === containerRef.id &&
                    c.version === containerRef.version
                );
                if (index <= 0) return;
                // reorder in one line
                [newOrderedContainers[index - 1], newOrderedContainers[index]] = [newOrderedContainers[index], newOrderedContainers[index - 1]];
                const updatedSection = Page.Section.update(
                    targetSection, { containers: newOrderedContainers }
                );
                newOrderedSectionsField.setValue(
                    newOrderedSectionsField.value.map(section => {
                        if (section.location === updatedSection.location) {
                            return updatedSection;
                        }
                        return section;
                    })
                );
                setDirty(true);
            };

            const moveDown = (containerRef: EntityRef) => {
                let targetSection;
                let targetContainer;
                let found = false;
                for (const section of newOrderedSectionsField.value) {
                    if (found) break;
                    for (const container of section.containers) {
                        if (found) break;
                        if (container.id === containerRef.id && container.version === containerRef.version) {
                            found = true;
                            targetSection = section;
                            targetContainer = container;
                        }
                    }
                }
                if (!found) return;
                const newOrderedContainers = targetSection.containers.slice();
                const index = newOrderedContainers.findIndex(c =>
                    c.id === containerRef.id &&
                    c.version === containerRef.version
                );
                if (index === newOrderedContainers.length - 1 || index < 0) return;
                // reorder in one line
                [newOrderedContainers[index + 1], newOrderedContainers[index]] = [newOrderedContainers[index], newOrderedContainers[index + 1]];
                const updatedSection = Page.Section.update(
                    targetSection, { containers: newOrderedContainers}
                );
                newOrderedSectionsField.setValue(
                    newOrderedSectionsField.value.map(section => {
                        if (section.location === updatedSection.location) {
                            return updatedSection;
                        }
                        return section;
                    })
                );
                setDirty(true);
            };

            return Builder<ReorderContainerState>(new ReorderContainerState())
                .draftField(draftField)
                .newOrderedSectionsField(newOrderedSectionsField)
                .reset(reset)
                .setDraft(setDraft)
                .moveUp(moveUp)
                .moveDown(moveDown)
                .isDirty(() => dirty)
                .build();
        }
    }

    export class VersionHistoryTableRowItemState {
        data: UIModel.State<PageDraft.Data>;
        version: number;
        id: string;

        static use(props: {
            draftId: string;
            version: number;
        }) {
            const context = React.useContext(AppContext);
            const loadDraftVersionRunner = useAPI(
                context.getPageDraftAPI().loadVersion
            );
            const data = UIModel.State.use<PageDraft.Data>({
                initialValue: null
            });

            React.useEffect(() => {
                loadDraftVersionRunner.submitRun(
                    EntityFactory.fromEntityAttributes(
                        props.draftId,
                        EntityType.PageDraft,
                        props.version
                    )
                );
            }, [props.draftId, props.version]);

            React.useEffect(() => {
                if (loadDraftVersionRunner.status === "Succeeded") {
                    data.setValue(loadDraftVersionRunner.data.output);
                }
            }, [loadDraftVersionRunner.status]);

            return Builder<VersionHistoryTableRowItemState>()
                .data(data)
                .version(props.version)
                .id(props.draftId)
                .build();
        }
    }
}