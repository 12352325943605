import {
    CollectionPreferencesProps,
    PaginationProps,
    PropertyFilterProps,
    TableProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { ILegalContact, ServerSidePaginatedLoadingOutput } from "@amzn/ask-legal-domain";
import { ComponnentProps, useComponnentProps } from "./polaris-component-hooks";
import { LegalContactsPolarisFactory } from "../factory/polaris/legal-contacts-polaris-factory";
import { API2Interface } from "./api-hook";
import { i18n } from "../i18n/propertyFilteringI18nString";
import { AppContext } from "../setup/context";

export interface ServerSideCollection<T> {
    tableProps: ComponnentProps<TableProps<T>>;
    preferenceProps: ComponnentProps<CollectionPreferencesProps>;
    paginationProps: ComponnentProps<PaginationProps>;
    filteringProps: ComponnentProps<PropertyFilterProps>;
    loadItems: () => void;
}

export function useServerSideCollection<T>(
    apiRunner: API2Interface<ILegalContact.LoadAllLegalContactsInput, ServerSidePaginatedLoadingOutput<T>>,
    instanceId: string,
    options: {
        columnDefinitions: TableProps.ColumnDefinition<any>[],
        defaultSortingColumn: TableProps.SortingColumn<T>,
        filteringProperties: PropertyFilterProps.FilteringProperty[],
        selectionType?: "single" | "multi"
    }
): ServerSideCollection<T> {
    const tableProps = useComponnentProps<TableProps<T>>({
        items: [],
        totalItemsCount: 0,
        columnDefinitions: options.columnDefinitions,
        selectedItems: [],
        selectionType: options.selectionType,
        onSelectionChange: e => tableProps.setProps((prev) => ({
            ...prev,
            selectedItems: e.detail.selectedItems
        })),
        sortingColumn: options.defaultSortingColumn,
        sortingDescending: false,
        onSortingChange: e => tableProps.setProps((prev) => ({
            ...prev,
            sortingColumn: { sortingField: e.detail.sortingColumn.sortingField },
            sortingDescending: e.detail.isDescending
        })),
        loadingText: "Loading...",
        loading: true
    });
    const context = React.useContext(AppContext);

    const paginationProps = useComponnentProps<PaginationProps>({
        currentPageIndex: 1,
        pagesCount: 0,
        onChange: (e) => {
            paginationProps.setProps((prev) => ({
                ...prev,
                currentPageIndex: e.detail.currentPageIndex
            }));
        }
    });

    const preferenceProps = useComponnentProps<CollectionPreferencesProps>({
        cancelLabel: "Cancel",
        confirmLabel: "Confirm",
        title: "Table Preferences",
        preferences: LegalContactsPolarisFactory.DEFAULT_PREFERENCES(
            Array.from(context.getPreference().labs)
        ),
        pageSizePreference: {
            title: "Select page size",
            options: LegalContactsPolarisFactory.DEFAULT_PAGE_SIZE_OPTIONS
        },
        onConfirm: (e) => {
            preferenceProps.setProps((prev) => ({
                ...prev,
                preferences: e.detail
            }));
        }
    });

    const filteringQueryProps = useComponnentProps<PropertyFilterProps.Query>({
        operation: "or",
        tokens: []
    });

    const filteringProps = useComponnentProps<PropertyFilterProps>({
        query: filteringQueryProps.props,
        onChange: e => filteringQueryProps.setProps(prev => ({
            ...prev,
            operation: e.detail.operation,
            tokens: e.detail.tokens.map(t => ({
                ...t,
                propertyKey: t.propertyKey
            }))
        })),
        filteringProperties: options.filteringProperties,
        filteringOptions: [],
        i18nStrings: i18n,
        hideOperations: true,
    });

    const getLegalContactFilters = () => {
        if (filteringProps.props.query.tokens.length === 0 || !filteringProps.props.query.operation) return undefined;
        return {
            operation: filteringProps.props.query.operation === "or" ? "Or" : "And",
            propertyTokens: filteringProps.props.query.tokens.map((token) => ({
                name: token.propertyKey || LegalContactsPolarisFactory.Filters.PropertyFilters.LegalContactUserName.key,
                value: token.value,
                operator: token.operator || ":"
            }))
        };
    };

    const loadItems = () => {
        apiRunner.invoke(
            ILegalContact.LoadAllLegalContactsInput.create({
                instanceId: instanceId,
                pagination: {
                    currentPageIndex: paginationProps.props.currentPageIndex,
                    pageSize: preferenceProps.props.preferences.pageSize,
                },
                sorting: {
                    field: tableProps.props.sortingColumn.sortingField,
                    order: tableProps.props.sortingDescending ? "DESC" : "ASC"
                },
                filters: getLegalContactFilters()
            })
        );
    };

    React.useEffect(() => {
        loadItems();
    }, [
        instanceId,
        tableProps.props.sortingColumn,
        tableProps.props.sortingDescending,
        paginationProps.props.currentPageIndex,
        filteringProps.props.query
    ]);

    React.useEffect(() => {
        if (apiRunner.status === "Succeeded") {
            tableProps.setProps(prev => ({
                ...prev,
                loading: false,
                items: apiRunner.output.result,
                totalItemsCount: apiRunner.output.totalCount
            }));
            paginationProps.setProps(prev => ({
                ...prev,
                pagesCount: Math.floor(
                    (apiRunner.output.totalCount + preferenceProps.props.preferences.pageSize - 1) / preferenceProps.props.preferences.pageSize
                ),
                disabled: false
            }));
            preferenceProps.setProps(prev => ({
                ...prev,
                disabled: false
            }));
        } else if (apiRunner.status === "Running") {
            tableProps.setProps((prev) => ({
                ...prev,
                loading: true
            }));
            paginationProps.setProps((prev) => ({
                ...prev,
                disabled: true
            }));
            preferenceProps.setProps((prev) => ({
                ...prev,
                disabled: true
            }));
        } else if (apiRunner.status === "Error") {
            tableProps.setError(apiRunner.err);
            tableProps.setProps((prev) => ({
                ...prev,
                loading: false,
                selectedItems: []
            }));
            paginationProps.setProps((prev) => ({
                ...prev,
                disabled: false
            }));
            preferenceProps.setProps((prev) => ({
                ...prev,
                disabled: false
            }));
        }
    }, [apiRunner.status]);

    React.useEffect(() => {
        tableProps.setProps(prev => ({
            ...prev,
            visibleColumns: preferenceProps.props.preferences.visibleContent
        }));
    }, [preferenceProps.props.preferences.visibleContent]);

    React.useEffect(() => {
        filteringProps.setProps(prev => ({
            ...prev,
            query: filteringQueryProps.props
        }));
    }, [filteringQueryProps.props]);

    // When page size changes, change current page index back to 1
    React.useEffect(() => {
        if (tableProps.props.loading) return;
        if (paginationProps.props.currentPageIndex !== 1) {
            paginationProps.setProps(prev => ({
                ...prev,
                currentPageIndex: 1
            }));
        } else {
            loadItems();
        }
    }, [preferenceProps.props.preferences.pageSize]);

    return {
        tableProps,
        preferenceProps,
        paginationProps,
        filteringProps,
        loadItems
    };
}