import * as React from "react";
import { UIModel } from "../../../model/ui-model";
import {
    Checkbox,
    CollectionPreferences,
    ColumnLayout,
    Header,
    Select,
    SpaceBetween,
    Tabs,
    TabsProps
} from "@amzn/awsui-components-react";
import { UIField } from "../../common/UIField";
import { WhosMyLawyerFactory } from "../../../factory/whos-my-lawyer-factory";
import { ConfigurableOption, WhosMyLawyerContent } from "@amzn/ask-legal-domain";
import { LegalContactsPolarisFactory } from "../../../factory/polaris/legal-contacts-polaris-factory";
import { ConfigurableOptionsInput } from "../../common/ConfigurableOptionsInput";
import { Preference } from "../../../setup/preference";
import { AppContext } from "../../../setup/context";

export const WhosMyLawyerContentEdit = (props: {
    state: UIModel.State<WhosMyLawyerContent>;
}) => {
    const context = React.useContext(AppContext);
    const renderedTabs: TabsProps.Tab[] = [];
    if (context.hasLab(Preference.Lab.WhosMyLawyerConfigurableOptions)) {
        renderedTabs.push({
            id: "scope",
            label: "Scope",
            content: <ConfigurableOptionsInput
                state={UIModel.State.use<ConfigurableOption[]>({
                    initialValue: props.state.value?.dataOptions?.scopeOptions || []
                })}
                onChange={(updatedOptions) => {
                    props.state.setValue(WhosMyLawyerContent.create({
                        ...props.state.value?.defaultConfiguration,
                        ...props.state.value?.dataOptions,
                        scopeOptions: updatedOptions
                    }));
                }}
                headerTitle="Scope Dropdown Options"
            />
        });
    }
    if (context.hasLab(Preference.Lab.WhosMyLawyerPhase2)) {
        renderedTabs.push({
            id: "team",
            label: "Team",
            content: <ConfigurableOptionsInput
                state={UIModel.State.use<ConfigurableOption[]>({
                    initialValue: props.state.value?.dataOptions?.teamOptions || []
                })}
                onChange={(updatedOptions) => {
                    props.state.setValue(WhosMyLawyerContent.create({
                        ...props.state.value?.defaultConfiguration,
                        ...props.state.value?.dataOptions,
                        teamOptions: updatedOptions
                    }));
                }}
                headerTitle="Team Dropdown Options"
            />
        });
    }

    return <>
        <hr />
        <Header variant="h3">Default Table View Configuration</Header>
        <ColumnLayout columns={2}>
            <UIField.CustomField
                name="Sorting field"
                child={<Select
                    selectedOption={WhosMyLawyerFactory.toOptionDefinition(
                        props.state.value?.defaultConfiguration.sorting?.field
                    )}
                    onChange={({ detail }) => props.state.setValue(
                        WhosMyLawyerContent.create({
                            ...props.state.value.defaultConfiguration,
                            sorting: {
                                field: detail.selectedOption.value,
                                order: props.state.value.defaultConfiguration.sorting.order
                            }
                        })
                    )}
                    options={WhosMyLawyerFactory.getSortSelectOptions()}
                />}
            />
            <UIField.CustomField
                name="Sorting order"
                child={<Checkbox
                    checked={props.state.value?.defaultConfiguration.sorting?.order === "ASC"}
                    onChange={({ detail }) => props.state.setValue(
                        WhosMyLawyerContent.create({
                            ...props.state.value.defaultConfiguration,
                            sorting: {
                                field: props.state.value.defaultConfiguration.sorting.field,
                                order: detail.checked ? "ASC" : "DESC"
                            }
                        })
                    )}
                >
                    isAscending
                </Checkbox>}
            />
            <UIField.LabelField
                label="Manage view preferences"
                children={<CollectionPreferences
                    preferences={WhosMyLawyerFactory.toPreferences(
                        props.state.value,
                        Array.from(context.getPreference().labs)
                    )}
                    onConfirm={({ detail }) => props.state.setValue(
                        WhosMyLawyerFactory.toContentFromPreferences(
                            detail,
                            props.state.value
                        )
                    )}
                    pageSizePreference={{
                        options: LegalContactsPolarisFactory.DEFAULT_PAGE_SIZE_OPTIONS,
                        title: "Number of contacts per page"
                    }}
                    stripedRowsPreference={{
                        label: "Striped rows",
                        description: "Select to add alternating shaded rows"
                    }}
                    contentDisplayPreference={{
                        options: LegalContactsPolarisFactory.Table.getColumnDisplayOptions(
                            Array.from(context.getPreference().labs)
                        ),
                        title: "Default column view preferences",
                        description: "Customize the columns visibility and order"
                    }}
                    cancelLabel="Cancel"
                    confirmLabel="Save"
                    title="Set default table preferences"
                />}
            />
        </ColumnLayout>
        {(
            context.hasLab(Preference.Lab.WhosMyLawyerConfigurableOptions) ||
            context.hasLab(Preference.Lab.WhosMyLawyerPhase2)
        ) && <>
            <hr />
            <SpaceBetween size="s">
                <Header variant="h3">Configure Dropdown Options</Header>
                <Tabs
                    tabs={renderedTabs}
                    variant="container"
                />
            </SpaceBetween>
        </>}
    </>;
};