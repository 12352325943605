import {
    Button,
    Grid,
    Input,
    SpaceBetween,
    TokenGroup,
    TokenGroupProps
} from "@amzn/awsui-components-react";
import { Builder } from "builder-pattern";
import * as React from "react";

export const toTokenGroupProps = (values: string[]): TokenGroupProps.Item[] => {
    return values.map(x => Builder<TokenGroupProps.Item>()
        .label(x)
        .dismissLabel(`Remove ${x}`)
        .build());
};

export const InputTokens = (props: {
    values: string[];
    setValues: (values: string[]) => void;
}) => {
    const [inputText, setInputText] = React.useState("");

    const onItemAdd = () => {
        if (inputText.trim().length > 0) {
            props.setValues([
                ...props.values,
                inputText.trim()
            ]);
            setInputText("");
        }
    };

    return (
        <SpaceBetween size="m">
            <Grid
                gridDefinition={[
                    { colspan: 10 },
                    { colspan: 2 }
                ]}
            >
                <Input
                    value={inputText}
                    onChange={({ detail }) => setInputText(detail.value)}
                    onKeyDown={({ detail }) => {
                        if (detail.keyCode === 13) {
                            onItemAdd();
                        }
                    }}
                />
                <Button variant="icon" iconName="add-plus" onClick={onItemAdd}/>
            </Grid>
            {props.values.length > 0 && <TokenGroup
                items={toTokenGroupProps(props.values)}
                onDismiss={({ detail: { itemIndex }}) => {
                    props.setValues([
                        ...props.values.slice(0, itemIndex),
                        ...props.values.slice(itemIndex + 1)
                    ]);
                }}
                i18nStrings={{
                    limitShowMore: "Show more",
                    limitShowFewer: "Show fewer"
                }}
                limit={3}
            />}
        </SpaceBetween>
    );
};