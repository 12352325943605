import * as React from "react";
import {
    AccordionContent,
    AccordionGroupContent,
    Container,
    ContainerContentType,
    CustomListContent,
    DecisionTreeContent,
    DocumentContent,
    RichTextContent,
    WhosMyLawyerContent
} from "@amzn/ask-legal-domain";
import { AccordionGroupEdit } from "../accordian-group/component";
import { BasicAccordionEdit } from "../basic-accordian/component";
import { UIModel } from "../../../model/ui-model";
import { CustomListContentEdit } from "./CustomListContentEdit";
import { DocumentContentEdit } from "./DocumentContentEdit";
import { DecisionTreeContentEdit } from "./DecisionTreeContentEdit";
import { WhosMyLawyerContentEdit } from "./WhosMyLawyerContentEdit";
import { StrongRichTextEdit } from "../../common/RichTextEditor/TinyMCEBased/StrongRichTextEdit";

export const SmartContainerContentEdit = (props: {
    containerType: Container.Type;
    state: UIModel.State<ContainerContentType>;
}) => {
    let renderedEditContent: JSX.Element | undefined;
    switch (props.containerType) {
        case Container.Type.RICH_TEXT:
            renderedEditContent = <StrongRichTextEdit
                content={!props.state.value ?
                    RichTextContent.create().contentValue :
                    (props.state.value as RichTextContent).contentValue
                }
                onChange={(e) => {
                    props.state.setValue(RichTextContent.create(e));
                }}
            />;
            break;
        case Container.Type.ACCORDION:
            renderedEditContent = <BasicAccordionEdit
                existingQuestions={!props.state.value ?
                    AccordionContent.create().contentValue :
                    (props.state.value as AccordionContent).contentValue
                }
                onChange={(e) => { props.state.setValue(AccordionContent.create(e)); }}
            />;
            break;
        case Container.Type.ACCORDION_GROUP:
            renderedEditContent = <AccordionGroupEdit
                existingQuestions={!props.state.value ?
                    AccordionGroupContent.create().contentValue :
                    (props.state.value as AccordionGroupContent).contentValue
                }
                onChange={(e) => { props.state.setValue(AccordionGroupContent.create(e)); }}
            />;
            break;
        case Container.Type.LIST:
            renderedEditContent = <CustomListContentEdit state={props.state as UIModel.State<CustomListContent>} />;
            break;
        case Container.Type.DOCUMENT:
            renderedEditContent = <DocumentContentEdit state={props.state as UIModel.State<DocumentContent>} />;
            break;
        case Container.Type.DECISION_TREE:
            renderedEditContent = <DecisionTreeContentEdit state={props.state as UIModel.State<DecisionTreeContent>} />;
            break;
        case Container.Type.WHOS_MY_LAWYER:
            renderedEditContent = <WhosMyLawyerContentEdit state={props.state as UIModel.State<WhosMyLawyerContent>} />;
            break;
        default:
            renderedEditContent = <div>Unsupported content type</div>;
            break;
    }
    return renderedEditContent;
};