import { CognitoConst, Identity, IdentityType } from "@amzn/ask-legal-domain";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import { Builder } from "builder-pattern";
import { ContainerAPI } from "../api/container.api";
import { EntityPermissionAPI } from "../api/entity-permission.api";
import { CommonAPI } from "../api/entity.api";
import { ExternalReferenceAPI } from "../api/external-reference.api";
import { FileAPI } from "../api/file.api";
import { InstanceAPI } from "../api/instance.api";
import { LabelAPI } from "../api/label.api";
import { NavigationAPI } from "../api/navigation.api";
import { PageDraftAPI } from "../api/page-draft.api";
import { PageLibraryAPI } from "../api/page-library.api";
import { PageAPI } from "../api/page.api";
import { RecommendationRuleAPI } from "../api/recommendation-rule.api";
import { RecommendationAPI } from "../api/recommendation.api";
import { RoleAPI } from "../api/role.api";
import { SearchAPI } from "../api/search.api";
import { TeamAPI } from "../api/teams.api";
import { UserProfileAPI } from "../api/user-profile.api";
import { UserSearchAPI } from "../api/user-search.api";
import { AmplifyFactory } from "../factory/amplify-factory";
import { ClientConfig, ClientConfigFactory } from "./config";
import { Preference } from "./preference";
import { UserActivityAPI } from "../api/user-activity.api";
import { DecisionTreeNodeAPI } from "../api/decision-tree-node.api";
import { LegalContactAPI } from "../api/legal-contact.api";

export interface IdentityWithCustomFields extends Identity {
    costCenterId: string;
    tenure: string;
    country: string;
}

export interface AppContextValue {

    init(): Promise<void>;
    signIn(): Promise<void>;
    signOut(): Promise<void>;
    currentUser(): Promise<CognitoUser>;
    getIdentity(): Promise<Identity>;
    getIdentityWithCustomFields(): Promise<IdentityWithCustomFields>;
    getConfig(): Promise<ClientConfig>;
    getStage(): string;

    setPreference(instanceId?: string, labs?: Set<Preference.Lab>): Promise<void>;
    getPreference(): Preference.Value;
    hasLab(lab: Preference.Lab): boolean;
    setLocalStorage<T>(key: string, value: T): void;
    getLocalStorage<T>(key: string): T;

    getInstanceAPI(): InstanceAPI;
    getTeamAPI(): TeamAPI;
    getPageLibraryAPI(): PageLibraryAPI;
    getPageAPI(): PageAPI;
    getEntityPermissionAPI(): EntityPermissionAPI;
    getContainerAPI(): ContainerAPI;
    getPageDraftAPI(): PageDraftAPI;
    getFileAPI(): FileAPI;
    getNavigationAPI(): NavigationAPI;
    getSearchAPI(): SearchAPI;
    getRecommendationRuleAPI(): RecommendationRuleAPI;
    getRecommendationAPI(): RecommendationAPI;
    getRoleAPI(): RoleAPI;
    getUserProfileAPI(): UserProfileAPI;
    getUserSearchAPI(): UserSearchAPI;
    getCommonAPI(): CommonAPI;
    getLabelAPI(): LabelAPI;
    getExternalReferenceAPI(): ExternalReferenceAPI;
    getUserActivityAPI(): UserActivityAPI;
    getDecisionTreeNodeAPI(): DecisionTreeNodeAPI;
    getLegalContactAPI(): LegalContactAPI;
}

export abstract class AbsAppContextValueImpl implements AppContextValue {

    stage: string;
    async setPreference(instanceId?: string, labs?: Set<Preference.Lab>): Promise<void> {
        const identity = await this.getIdentity();
        const originalStorage = this.getLocalStorage<Preference.Value>(Preference.KEY);
        this.setLocalStorage(Preference.KEY, {
            userId: identity.id,
            instanceId: instanceId ?
                instanceId :
                ((originalStorage && originalStorage.instanceId) ? originalStorage.instanceId : null),
            labs: labs ?
                Array.from(labs) :
                ((originalStorage && originalStorage.labs) ? Array.from(originalStorage.labs) : [])
        });
    }

    getPreference(): Preference.Value {
        const pref = this.getLocalStorage<Preference.Value>(Preference.KEY);
        if (!pref) return {
            userId: null,
            instanceId: null,
            labs: new Set(),
        };
        const labs = pref.labs ? pref.labs : [];
        return {
            ...pref,
            labs: new Set(labs)
        };
    }

    hasLab(lab: Preference.Lab) {
        const labs = this.getPreference().labs;
        return labs.has(lab);
    }

    setLocalStorage<T>(key: string, value: T): void {
        if (value) localStorage.setItem(key, JSON.stringify(value));
        else localStorage.removeItem(key);
    }
    getLocalStorage<T>(key: string): T {
        const v = localStorage.getItem(key);
        if (v) return JSON.parse(v);
        return null;
    }

    config: ClientConfig;

    async init (): Promise<void> {
        this.config = await this.getConfig();
        AmplifyFactory.createAuth(this.config);
        AmplifyFactory.createRestAPI(this.config);
        this.stage = this.config.stage;
    }

    async signIn (): Promise<void> {
        console.log("signIn()");
        await Auth.federatedSignIn({
            customProvider: "AmazonFederateOIDC"
        });
    }

    async signOut (): Promise<void> {
        await Auth.signOut();
        await this.signIn();
    }

    async currentUser (): Promise<CognitoUser> {
        const user: CognitoUser = await Auth.currentAuthenticatedUser();
        console.log(
            `current user is ${JSON.stringify(
                user.getSignInUserSession().getIdToken().decodePayload().identities[0].userId
            )}`
        );
        return user;
    }

    async getIdentityWithCustomFields (): Promise<IdentityWithCustomFields> {
        const token = (await this.currentUser())
            .getSignInUserSession()
            .getIdToken()
            .decodePayload();
        const cognitoIdentity = Builder<IdentityWithCustomFields>()
            .type(IdentityType.Person)
            .id(token.identities[0].userId.split("@")[0])
            .name(`${token.given_name} ${token.family_name}`)
            .email(token.email)
            .country(token[`custom:${CognitoConst.CustomAttributes.COUNTRY}`])
            .tenure(token[`custom:${CognitoConst.CustomAttributes.TENURE}`])
            .costCenterId(token[`custom:${CognitoConst.CustomAttributes.DEPARTMENT_NUMBER}`])
            .build();
        return cognitoIdentity;
    }

    async getIdentity (): Promise<Identity> {
        const token = (await this.currentUser())
            .getSignInUserSession()
            .getIdToken()
            .decodePayload();
        const cognitoIdentity = Builder<Identity>()
            .type(IdentityType.Person)
            .id(token.identities[0].userId.split("@")[0])
            .name(`${token.given_name} ${token.family_name}`)
            .email(token.email)
            .build();
        return cognitoIdentity;
    }

    async getConfig (): Promise<ClientConfig> {
        return await ClientConfigFactory.getConfig();
    }

    getStage(): string {
        return this.stage;
    }

    getInstanceAPI (): InstanceAPI {
        throw new Error("Instance API not implemented.");
    }
    getTeamAPI (): TeamAPI {
        throw new Error("Team API not implemented.");
    }
    getPageLibraryAPI (): PageLibraryAPI {
        throw new Error("Page Library API not implemented.");
    }
    getPageAPI (): PageAPI {
        throw new Error("Page Library API not implemented.");
    }
    getEntityPermissionAPI(): EntityPermissionAPI {
        throw new Error("Method not implemented.");
    }
    getContainerAPI(): ContainerAPI {
        throw new Error("Method not implemented.");
    }
    getPageDraftAPI(): PageDraftAPI {
        throw new Error("Method not implemented.");
    }
    getFileAPI(): FileAPI {
        throw new Error("Method not implemented.");
    }
    getNavigationAPI(): NavigationAPI {
        throw new Error("Method not implemented.");
    }
    getSearchAPI(): SearchAPI {
        throw new Error("Method not implemented.");
    }
    getRecommendationRuleAPI(): RecommendationRuleAPI {
        throw new Error("Method not implemented.");
    }
    getRecommendationAPI(): RecommendationAPI {
        throw new Error("Method not implemented.");
    }
    getRoleAPI(): RoleAPI {
        throw new Error("Method not implemented.");
    }
    getUserProfileAPI(): UserProfileAPI {
        throw new Error("Method not implemented.");
    }
    getUserSearchAPI(): UserSearchAPI {
        throw new Error("Method not implemented.");
    }
    getCommonAPI(): CommonAPI {
        throw new Error("Method not implemented.");
    }
    getLabelAPI(): LabelAPI {
        throw new Error("Method not implemented.");
    }
    getExternalReferenceAPI(): ExternalReferenceAPI {
        throw new Error("Method not implemented.");
    }
    getUserActivityAPI(): UserActivityAPI {
        throw new Error("Method not implemented.");
    }
    getDecisionTreeNodeAPI(): DecisionTreeNodeAPI {
        throw new Error("Method not implemented.");
    }
    getLegalContactAPI(): LegalContactAPI {
        throw new Error("Method not implemented.");
    }
}
