import {
    AccordionContent,
    AccordionGroupContent,
    Container,
    ContainerContentType,
    ContainerFactory,
    CustomListContent,
    DecisionTreeContent,
    DocumentContent,
    Page,
    RichTextContent,
    WhosMyLawyerContent
} from "@amzn/ask-legal-domain";
import * as React from "react";
import { RichTextContentView } from "../view-content/RichTextContentView";
import { AccordionContentView } from "../view-content/AccordionContentView";
import { AccordionGroupContentView } from "../view-content/AccordionGroupContentView";
import { CustomListContentView } from "../view-content/CustomListContentView";
import { DocumentContentView } from "../view-content/DocumentContentView";
import { DecisionTreeContentView } from "../view-content/DecisionTreeContentView";
import { WhosMylawyerContentView } from "../view-content/WhosMyLawyerContentView";

export const ViewContainerContent = (props: {
    containerMetadata: Container.Data;
    content: ContainerContentType;
    pageSection?: Page.SectionLocation;
    expandCollapseAllTrigger?: { value: string; };
    showEditControls?: boolean;
}) => {
    let renderedView: JSX.Element;

    switch (props.containerMetadata.containerType) {
        case Container.Type.RICH_TEXT:
            renderedView = <RichTextContentView
                content={props.content as RichTextContent}
            />;
            break;
        case Container.Type.ACCORDION:
            renderedView = <AccordionContentView
                content={props.content as AccordionContent}
                headerData={props.containerMetadata.headerDescription}
                footerData={props.containerMetadata.footerDescription}
                expandCollapseAllTrigger={props.expandCollapseAllTrigger}
            />;
            break;
        case Container.Type.ACCORDION_GROUP:
            renderedView = <AccordionGroupContentView
                content={props.content as AccordionGroupContent}
                headerData={props.containerMetadata.headerDescription}
                footerData={props.containerMetadata.footerDescription}
                expandCollapseAllTrigger={props.expandCollapseAllTrigger}
            />;
            break;
        case Container.Type.DOCUMENT:
            renderedView = <DocumentContentView
                content={props.content as DocumentContent}
                headerData={props.containerMetadata.headerDescription}
                footerData={props.containerMetadata.footerDescription}
            />;
            break;
        case Container.Type.LIST:
            renderedView = <CustomListContentView
                content={props.content as CustomListContent}
                containerId={
                    props.pageSection! === "Right" ?
                        props.containerMetadata.id :
                        undefined
                }
            />;
            break;
        case Container.Type.DECISION_TREE:
            renderedView = <DecisionTreeContentView
                content={props.content as DecisionTreeContent}
                containerId={props.containerMetadata.id}
            />;
            break;
        case Container.Type.WHOS_MY_LAWYER:
            renderedView = <WhosMylawyerContentView
                content={props.content as WhosMyLawyerContent}
                containerRef={ContainerFactory.toContainerRef(props.containerMetadata)}
                showEditControls={props.showEditControls}
            />;
            break;
        default:
            renderedView = <div>Container Type not supported</div>;
            break;
    }

    return renderedView;
};