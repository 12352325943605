import * as React from "react";
import { AppContext } from "../../../setup/context";
import { useAPI2 } from "../../../hooks/api-hook";
import { LegalContactModel } from "../../../model/legal-contact-model";
import { SimpleModal } from "../../common/SimpleModal";
import { EditLegalContactForm } from "../EditLegalContactForm";
import { LegalContact } from "@amzn/ask-legal-domain";
import { SpaceBetween } from "@amzn/awsui-components-react";
import { ErrorFlashbar } from "../../common/ErrorFlashbar";

export const CreateLegalContactModal = (props: {
    state: LegalContactModel.UpdateState;
    onDismiss: () => void;
    onCreate: (createdOutput: LegalContact.Record) => void;
}) => {
    const context = React.useContext(AppContext);
    const createLegalContactRunner = useAPI2(
        context.getLegalContactAPI().create
    );

    const onSave = () => {
        createLegalContactRunner.invoke(
            LegalContactModel.UpdateState.toCreateInput(props.state)
        );
    };

    React.useEffect(() => {
        if (createLegalContactRunner.status === "Succeeded") {
            props.onCreate(createLegalContactRunner.output);
            props.onDismiss();
        }
    }, [createLegalContactRunner.status]);

    return (
        <SimpleModal
            child={
                <SpaceBetween size="s">
                    {createLegalContactRunner.status === "Error" && <ErrorFlashbar error={createLegalContactRunner.err} />}
                    <EditLegalContactForm state={props.state} />
                </SpaceBetween>
            }
            header="Create New Legal Contact"
            loading={createLegalContactRunner.status === "Running"}
            onCancel={props.onDismiss}
            onSave={onSave}
            disabled={!!props.state.legalContactUser.errorText ||
                !!props.state.businessLeader.errorText ||
                !!props.state.team.errorText ||
                !!props.state.subTeam.errorText ||
                !!props.state.scope.errorText
            }
            size="max"
        />
    );
};