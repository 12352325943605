import { Box, Button, ExpandableSection, SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { UserSearch } from "../../common/UserSearch";
import { EmployeeIdentity, LegalContact, WhosMyLawyerContent } from "@amzn/ask-legal-domain";
import { LegalContactTable } from "../LegalContactTable";
import { WhosMyLawyerFactory } from "../../../factory/whos-my-lawyer-factory";
import { AppContext } from "../../../setup/context";

export const ViewRecommendationsComp = (props: {
    items: LegalContact.Record[];
    content: WhosMyLawyerContent;
    costCenterId: string;
    reloadAction: () => void;
    loading?: boolean;
}) => {
    const [simulatedUser, setSimulatedUser] = React.useState<EmployeeIdentity>(null);
    const [costCenterId, setCostCenterId] = React.useState<string>(props.costCenterId);
    const context = React.useContext(AppContext);

    React.useEffect(() => {
        if (
            simulatedUser &&
            simulatedUser.employeeDetails?.costCenterId! &&
            simulatedUser.employeeDetails.costCenterId.length > 0
        ) setCostCenterId(simulatedUser.employeeDetails.costCenterId);
        else setCostCenterId(props.costCenterId);
    }, [simulatedUser]);

    return (
        <SpaceBetween size="m">
            <ExpandableSection
                defaultExpanded={false}
                variant="footer"
                headerText="Recommendation Simulator"
            >
                <SpaceBetween size="s" direction="horizontal" alignItems="center">
                    <Box textAlign="center" variant="awsui-key-label">View recommendations for</Box>
                    <UserSearch.Single
                        selected={simulatedUser}
                        onUserSelectChange={(s) => setSimulatedUser(s)}
                        includeEmployeeDetails={["costCenterId"]}
                    />
                    {simulatedUser && <Button
                        iconName="refresh"
                        variant="link"
                        onClick={() => setSimulatedUser(null)}
                    >Reset</Button>}
                </SpaceBetween>
            </ExpandableSection>
            <LegalContactTable
                records={props.items
                    .filter(l => l.businessLeaderCostCenterIds
                        ?.find(id => id.costCenterId === costCenterId)
                    )}
                reloadData={props.reloadAction}
                loading={props.loading}
                defaultSortState={WhosMyLawyerFactory.getSortingStateFromContent(props.content)}
                defaultPreference={WhosMyLawyerFactory.toPreferences(
                    props.content,
                    Array.from(context.getPreference().labs)
                )}
                headerContent="Recommended Contacts"
                headerDescription={<Box color="text-body-secondary">
                    Legal Contacts are recommended when they are associated with your Business Leader(s)
                </Box>}
                whosMyLawyerContent={props.content}
            />
        </SpaceBetween>
    );
};