import { InstanceAPI, InstanceAPIImpl } from "../api/instance.api";
import { AbsAppContextValueImpl } from "./context-value";
import { TeamAPI, TeamAPIImpl } from "../api/teams.api";
import { PageLibraryAPI, PageLibraryAPIImpl } from "../api/page-library.api";
import { EntityPermissionAPI, EntityPermissionAPIImpl } from "../api/entity-permission.api";
import { PageAPI, PageAPIImpl } from "../api/page.api";
import { ContainerAPI, ContainerAPIImpl } from "../api/container.api";
import { PageDraftAPI, PageDraftAPIImpl } from "../api/page-draft.api";
import { FileAPI, FileAPIImpl } from "../api/file.api";
import { NavigationAPI, NavigationAPIImpl } from "../api/navigation.api";
import { SearchAPI } from "../api/search.api";
import { RecommendationRuleAPI, RecommendationRuleAPIImpl } from "../api/recommendation-rule.api";
import { RecommendationAPI, RecommendationAPIImpl } from "../api/recommendation.api";
import { RoleAPI, RoleAPIImpl } from "../api/role.api";
import { UserProfileAPI, UserProfileAPIImpl } from "../api/user-profile.api";
import { UserSearchAPI, UserSearchAPIImpl } from "../api/user-search.api";
import { CommonAPI, CommonAPIImpl } from "../api/entity.api";
import { LabelAPI, LabelAPIImpl } from "../api/label.api";
import { ExternalReferenceAPI, ExternalReferenceAPIImpl } from "../api/external-reference.api";
import { UserActivityAPI, UserActivityAPIImpl } from "../api/user-activity.api";
import { DecisionTreeNodeAPI, DecisionTreeNodeAPIImpl } from "../api/decision-tree-node.api";
import { LegalContactAPI, LegalContactAPIImpl } from "../api/legal-contact.api";

export class AppContextValueImpl extends AbsAppContextValueImpl {
    private instanceApi: InstanceAPI = new InstanceAPIImpl(this);
    private teamApi: TeamAPI = new TeamAPIImpl(this);
    private pageLibraryApi: PageLibraryAPI = new PageLibraryAPIImpl(this);
    private pageApi: PageAPI = new PageAPIImpl(this);
    private entityPermissionApi: EntityPermissionAPI = new EntityPermissionAPIImpl(this);
    private containerApi: ContainerAPI = new ContainerAPIImpl(this);
    private pageDraftApi: PageDraftAPI = new PageDraftAPIImpl(this);
    private fileApi: FileAPI = new FileAPIImpl(this);
    private navigationApi: NavigationAPI = new NavigationAPIImpl(this);
    private searchApi: SearchAPI = new SearchAPI(this);
    private recommendationRuleApi: RecommendationRuleAPI = new RecommendationRuleAPIImpl(this);
    private recommendationApi: RecommendationAPI = new RecommendationAPIImpl(this);
    private roleApi: RoleAPI = new RoleAPIImpl(this);
    private userProfileApi: UserProfileAPI = new UserProfileAPIImpl(this);
    private userSearchApi: UserSearchAPI = new UserSearchAPIImpl(this);
    private commonApi: CommonAPI = new CommonAPIImpl(this);
    private labelApi: LabelAPI = new LabelAPIImpl(this);
    private externalReferenceApi: ExternalReferenceAPI = new ExternalReferenceAPIImpl(this);
    private userActivityApi: UserActivityAPI = new UserActivityAPIImpl(this);
    private decisionTreeNodeApi: DecisionTreeNodeAPI = new DecisionTreeNodeAPIImpl(this);
    private legalContactApi: LegalContactAPI = new LegalContactAPIImpl(this);

    getInstanceAPI () {
        return this.instanceApi;
    }

    getTeamAPI () {
        return this.teamApi;
    }

    getPageLibraryAPI () {
        return this.pageLibraryApi;
    }

    getPageAPI () {
        return this.pageApi;
    }

    getEntityPermissionAPI(): EntityPermissionAPI {
        return this.entityPermissionApi;
    }

    getContainerAPI(): ContainerAPI {
        return this.containerApi;
    }

    getPageDraftAPI(): PageDraftAPI {
        return this.pageDraftApi;
    }

    getFileAPI(): FileAPI {
        return this.fileApi;
    }

    getNavigationAPI(): NavigationAPI {
        return this.navigationApi;
    }

    getSearchAPI(): SearchAPI {
        return this.searchApi;
    }

    getRecommendationRuleAPI(): RecommendationRuleAPI {
        return this.recommendationRuleApi;
    }

    getRecommendationAPI(): RecommendationAPI {
        return this.recommendationApi;
    }

    getRoleAPI(): RoleAPI {
        return this.roleApi;
    }

    getUserProfileAPI(): UserProfileAPI {
        return this.userProfileApi;
    }

    getUserSearchAPI(): UserSearchAPI {
        return this.userSearchApi;
    }

    getCommonAPI(): CommonAPI {
        return this.commonApi;
    }

    getLabelAPI(): LabelAPI {
        return this.labelApi;
    }

    getExternalReferenceAPI(): ExternalReferenceAPI {
        return this.externalReferenceApi;
    }

    getUserActivityAPI(): UserActivityAPI {
        return this.userActivityApi;
    }

    getDecisionTreeNodeAPI(): DecisionTreeNodeAPI {
        return this.decisionTreeNodeApi;
    }

    getLegalContactAPI(): LegalContactAPI {
        return this.legalContactApi;
    }
}