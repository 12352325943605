import {
    Box,
    ColumnLayout,
    Link,
    Multiselect,
    Popover,
    Select,
    SelectProps
} from "@amzn/awsui-components-react";
import * as React from "react";
import { UIField } from "../common/UIField";
import { UserSearch } from "../common/UserSearch";
import { LegalContactModel } from "../../model/legal-contact-model";
import { InputTokens } from "../common/InputTokens";
import { ConfirmLeavePageModal } from "../common/ConfirmLeavePageModal";
import { ConfigurableOption, WhosMyLawyerContent } from "@amzn/ask-legal-domain";
import { AppContext } from "../../setup/context";
import { useAPI } from "../../hooks/api-hook";
import { Preference } from "../../setup/preference";

export const EditLegalContactForm = (props: {
    state: LegalContactModel.UpdateState
}) => {
    const [scopeOptions, setScopeOptions] = React.useState<ConfigurableOption[]>([]);
    const [teamOptions, setTeamOptions] = React.useState<ConfigurableOption[]>([]);
    const context = React.useContext(AppContext);
    const loadContainerRunner = useAPI(
        context.getContainerAPI().loadVersion
    );

    const toConfigurableOption = (selectOption: SelectProps.Option): ConfigurableOption => {
        return {
            id: selectOption.value,
            displayValue: selectOption.label
        };
    };
    const fromConfigurableOption = (option: ConfigurableOption): SelectProps.Option => {
        return {
            value: option.id,
            label: option.displayValue || option.id
        };
    };

    React.useEffect(() => {
        loadContainerRunner.submitRun(
            props.state.containerRef
        );
    }, [props.state?.containerRef]);

    React.useEffect(() => {
        if (loadContainerRunner.status === "Succeeded") {
            setScopeOptions(
                (loadContainerRunner.data.output.loadedContent as WhosMyLawyerContent).dataOptions?.scopeOptions || []
            );
            setTeamOptions(
                (loadContainerRunner.data.output.loadedContent as WhosMyLawyerContent).dataOptions?.teamOptions || []
            );
        }
    }, [loadContainerRunner.status]);

    return (
        <>
        <ConfirmLeavePageModal showModal={true} />
            <ColumnLayout columns={2}>
                <div style={{ maxWidth: "650px" }}>
                    <UIField.CustomField
                        name="Legal Contact"
                        errorText={props.state.legalContactUser.errorText}
                        child={
                            <UserSearch.Single
                                selected={props.state.legalContactUser.value}
                                onUserSelectChange={(selected) => props.state.legalContactUser.setValue(selected)}
                            />
                        }
                    />
                </div>
                <div style={{ maxWidth: "650px" }}>
                    <UIField.CustomField
                        name="Business Leader(s)"
                        helpInfo={<Popover
                            dismissAriaLabel="Close"
                            header="Legal Contact's Business Leaders"
                            triggerType="custom"
                            content={<Box color="text-body-secondary">
                                Select the L8 and/or L10 leaders of the organizations for which they are a primary business line legal contact.
                                <br /><br />
                                Business partners using this tool will receive recommendations on which legal contact to reach out to based on the leaders entered here.
                            </Box>}
                        >
                            <Link variant="info">Need Help?</Link>
                        </Popover>}
                        errorText={props.state.businessLeader.errorText}
                        child={
                            <UserSearch.Multiple
                                initialSelected={props.state.businessLeader.value}
                                onUserSelectChange={(selected) => props.state.businessLeader.setValue(selected)}
                            />
                        }
                    />
                </div>
                {!context.hasLab(Preference.Lab.WhosMyLawyerPhase2) && <UIField.StateValueField
                    name="Legal Team"
                    state={props.state.team}
                    editing
                />}
                {context.hasLab(Preference.Lab.WhosMyLawyerPhase2) && <UIField.CustomField
                    name="Legal Team"
                    errorText={props.state.teamOptions?.errorText}
                    child={
                        <Select
                            selectedOption={props.state.teamOptions?.value?.map((option) => ({
                                value: teamOptions.find(s => s.id === option.id)?.id,
                                label: teamOptions.find(s => s.id === option.id)?.displayValue || option.id
                            }))[0]}
                            statusType={loadContainerRunner.status === "Running" && "loading"}
                            loadingText={loadContainerRunner.status === "Running" && "Loading"}
                            options={teamOptions
                                .filter(o => !o.deprecated)
                                .map(o => fromConfigurableOption(o))
                            }
                            onChange={(d) => {
                                props.state.teamOptions.setValue(
                                    [toConfigurableOption(d.detail.selectedOption)]
                                );
                            }}
                            filteringType="auto"
                            placeholder="Choose a team"
                            empty="No teams found"
                        />
                    }
                />}
                <UIField.CustomField
                    name="Legal Sub Team"
                    errorText={props.state.subTeam.errorText}
                    child={
                        <InputTokens
                            values={props.state.subTeam.value}
                            setValues={props.state.subTeam.setValue}
                        />
                    }
                />
                {!context.hasLab(Preference.Lab.WhosMyLawyerConfigurableOptions) && <UIField.StateValueField
                    name="Scope"
                    state={props.state.scope}
                    helpInfo={<Popover
                        dismissAriaLabel="Close"
                        header="Legal Contact's Scope"
                        triggerType="custom"
                        content={<Box color="text-body-secondary">
                            Briefly describe the scope of their role, including specialized subject matter expertise, custom coverage details, and geography covered.
                            <br /><br />
                            (e.g., <em>Import Controls and Customs - EMEA, AWS Snow products, etc.</em>)
                        </Box>}
                    >
                        <Link variant="info">Need Help?</Link>
                    </Popover>}
                    variant="TextArea"
                    editing
                />}
                {context.hasLab(Preference.Lab.WhosMyLawyerConfigurableOptions) && (<>
                    <UIField.CustomField
                        name="Legal Scope"
                        errorText={props.state.scopeOptions?.errorText}
                        child={
                            <Multiselect
                                selectedOptions={props.state.scopeOptions?.value?.map((option) => {
                                    return {
                                        value: scopeOptions.find(s => s.id === option.id)?.id,
                                        label: scopeOptions.find(s => s.id === option.id)?.displayValue || option.id
                                    };
                                })}
                                statusType={loadContainerRunner.status === "Running" && "loading"}
                                loadingText={loadContainerRunner.status === "Running" && "Loading"}
                                options={scopeOptions
                                    .filter(o => !o.deprecated)
                                    .map(o => fromConfigurableOption(o))
                                }
                                onChange={(d) => {
                                    props.state.scopeOptions.setValue(
                                        d.detail.selectedOptions.map(o => toConfigurableOption(o))
                                    );
                                }}
                                filteringType="auto"
                                placeholder="Choose all applicable scope"
                                empty="No scopes found"
                            />
                        }
                    />
                </>)}
            </ColumnLayout>
        </>
    );
};